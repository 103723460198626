import { Breadcrumb } from 'components/UI/Breadcrumb';
import type { BreadcrumbItem } from 'components/UI/Breadcrumb/Breadcrumb';

export interface WidgetBreadcrumbDefaultProps {
  readonly model: {
    readonly breadcrumbs: Array<BreadcrumbItem>;
  };
  readonly title: string;
}

const WidgetBreadcrumbDefault = ({ context }: { context: WidgetBreadcrumbDefaultProps }) =>
  context.model.breadcrumbs?.length > 1 ? <Breadcrumb items={context.model.breadcrumbs} title={context.title} /> : null;

export default WidgetBreadcrumbDefault;
